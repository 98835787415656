import {
  createEffect,
  createMemo,
  createRenderEffect,
  createSignal,
  on,
  Show,
} from "solid-js";
import { createStore } from "solid-js/store";
import ProgramsList from "~/components/shared/ProgramsList";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type {
  Landing,
  ProgramRefWithLocation,
  ProgramRefWithLocationAndRegulations,
} from "~/types/drupal_jsonapi";

import "./LandingPrograms.css";
import { useSearchParams } from "@solidjs/router";
import {
  Pagination,
  paginationSlices,
  type ProgramPaginationStore,
} from "~/components/shared/Pagination";
import { getCanonicalPathFromMetaData } from "~/utils/tools";
import { urlRs } from "~/utils/url";
import { InputTypeLocationAutoComplete } from "~/components/Forms/Fields/Base/InputTypeLocationAutoComplete";
import type { SubmissionValues } from "~/types/common";
import { debounce } from "@solid-primitives/scheduled";
import { submitSearchParams } from "~/components/Search/server";
import { Select } from "~/components/Forms/Fields/Base/Select";

const debug = false;

type LandingProgramPaginationStore = ProgramPaginationStore & {
  sort: "cp" | "response" | "ville";
  scopedProgramsNids: number[] | undefined;
  regulations: string[] | undefined;
};

type LandingSearchResults = {
  nids: number[];
};

const [store, setStore] = createStore<LandingProgramPaginationStore>({
  sort: "cp",
  slices: [],
  currentPage: 0,
  displayedPrograms: [],
  scopedProgramsNids: undefined,
  regulations: [],
});

export default function LandingPrograms(props: {
  landing: Landing;
  enableRegulations?: boolean;
}) {
  const settings = useDrupalSettingsContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSearching, setIsSearching] = createSignal<boolean>(false);

  const [searchValuesStore, setSearchValuesStore] =
    createStore<SubmissionValues>({});

  const debouncedSearch = debounce(async (values: SubmissionValues) => {
    setIsSearching(true);
    const params = {
      landing_nid: props.landing.drupal_internal__nid + "",
      ...values,
    };

    function isSearchOk(
      response: LandingSearchResults | Error,
    ): response is LandingSearchResults {
      return "nids" in response!;
    }

    debug && console.log("Params", params);

    const c = await submitSearchParams<LandingSearchResults>(params);

    debug && console.log("Results", c);

    if (isSearchOk(c)) {
      setStore("scopedProgramsNids", c.nids);
      setStore("sort", "response");
    } else {
      setStore("scopedProgramsNids", undefined);
      setStore("sort", "cp");
    }

    setIsSearching(false);
  }, 500);

  createEffect(() => {
    debouncedSearch({
      ...searchValuesStore,
    });
  });

  const path = () => {
    const dest = getCanonicalPathFromMetaData(props.landing.metatag);
    const suffix = store.sort === "ville" ? "?sort=ville" : "";
    return urlRs("landings", dest + suffix, settings);
  };

  const sortFn = (a: ProgramRefWithLocation, b: ProgramRefWithLocation) => {
    if (store.sort === "cp") {
      return a.postal_code.localeCompare(b.postal_code);
    } else if (store.sort === "ville") {
      return a.city.localeCompare(b.city);
    } else if (store.sort === "response" && store.scopedProgramsNids) {
      // Sort based on the order of IDs in scopedProgramsNids array
      const indexA = store.scopedProgramsNids.indexOf(a.id);
      const indexB = store.scopedProgramsNids.indexOf(b.id);

      // If both IDs are found in the array, sort by their position
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      // If only one ID is found, prioritize it
      else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      }
      // If neither ID is found, fallback to postal code sorting
      return a.postal_code.localeCompare(b.postal_code);
    } else {
      return 0;
    }
  };

  const filterFn = (p: ProgramRefWithLocationAndRegulations) => {
    if (store.regulations?.length) {
      return p.regulations.some((regulation) =>
        store.regulations!.includes(regulation),
      );
    } else if (store.scopedProgramsNids) {
      return store.scopedProgramsNids.includes(p.id);
    } else {
      return true;
    }
  };

  createRenderEffect(() => {
    setStore("currentPage", parseInt((searchParams.page as string) || "0"));
  });

  createRenderEffect(() => {
    setStore("sort", (searchParams.sort as "cp" | "ville") || "cp");
  });

  const showPromoCard = () => {
    return settings.promo_card.disp_landings && store.currentPage === 0;
  };

  const filteredPrograms = createMemo(
    on(
      () => [store.scopedProgramsNids, store.sort, store.regulations],
      () => props.landing.programs?.filter(filterFn) ?? [],
    ),
  );

  createRenderEffect(() => {
    const slices = paginationSlices(filteredPrograms().toSorted(sortFn), {
      insertPromoCard: settings.promo_card.disp_landings,
    });
    setStore("slices", slices);
  });

  createEffect(() => {
    on(
      () => [store.scopedProgramsNids, store.sort, store.slices],
      () => {
        setSearchParams({ page: 0, sort: store.sort, r: searchParams.r });
      },
    );
  });

  createEffect(() => {
    if (store.slices[store.currentPage]) {
      setStore(
        "displayedPrograms",
        store.slices[store.currentPage].filter(Boolean),
      );
    } else {
      setStore("displayedPrograms", []);
    }
  });

  const investRegulations = [
    {
      label: "LMNP",
      value: "LMNP",
      name: "lmnp-vat-inc",
      disabled: false,
      isDwell: false,
      isInvest: true,
    },
    {
      label: "LMNP géré",
      value: "LMNP géré",
      name: "lmnp-vat-ex",
      disabled: false,
      isDwell: false,
      isInvest: true,
    },
    {
      label: "Nue-propriété",
      value: "Nue-propriété",
      name: "bare-ownership",
      disabled: false,
      isDwell: false,
      isInvest: true,
    },
    {
      label: "Patrimonial",
      value: "Patrimonial",
      name: "patrimonial",
      disabled: false,
      isDwell: false,
      isInvest: true,
    },
  ];

  const availableRegulations = createMemo(() => {
    return investRegulations.filter(function (regulation) {
      return props.landing.programs.some(function (p) {
        return p.regulations.includes(regulation.value);
      });
    });
  });

  return (
    <>
      <section
        class="programs-landing"
        id="programs-landing"
        data-test="programs-landing"
      >
        <div class="content-part">
          <Show when={props.landing.field_programs_title}>
            <h2 class="title">{props.landing.field_programs_title}</h2>
          </Show>

          <Show
            when={props.landing.programs && props.landing.programs.length > 0}
          >
            <div class="filters" data-test="filters">
              <Show when={props.enableRegulations}>
                <div className="search-input">
                  <Select
                    label="Dispositif fiscal"
                    name="regulations"
                    options={availableRegulations()}
                    defaultFormStorage={setStore}
                    multiple
                  />
                </div>
              </Show>

              <Show when={!props.enableRegulations}>
                <div class="search-input">
                  <InputTypeLocationAutoComplete
                    name="location"
                    label="Ville, dpt., programme"
                    defaultFormStorage={setSearchValuesStore}
                    value={searchValuesStore.location}
                    valueCity={searchValuesStore.city}
                    valueDepartement={searchValuesStore.department}
                    valueRegion={searchValuesStore.region}
                    valueCountry={searchValuesStore.country}
                    valuePostalCode={searchValuesStore.postal_code}
                    valueLat={
                      searchValuesStore.lat
                        ? parseFloat(searchValuesStore.lat)
                        : undefined
                    }
                    valueLng={
                      searchValuesStore.lng
                        ? parseFloat(searchValuesStore.lng)
                        : undefined
                    }
                    onInput={() => {
                      debouncedSearch({
                        ...searchValuesStore,
                      });
                    }}
                    onChange={() => {
                      debouncedSearch({
                        ...searchValuesStore,
                      });
                    }}
                  />

                  <Show when={debug}>
                    <pre>
                      searchValuesStore:{" "}
                      {JSON.stringify(searchValuesStore, null, 2)}
                    </pre>
                  </Show>

                  <Show when={isSearching()}>
                    <div class="spinner">
                      <i aria-hidden="true" class="loading-spinner" />
                    </div>
                  </Show>
                </div>
              </Show>

              <Show when={debug}>
                <pre>
                  Params: {JSON.stringify(searchParams, null, 2)}
                  Filtered programs: {filteredPrograms().length}
                  <br />
                  Current page: {store.currentPage}
                  <br />
                  Sort: {store.sort}
                  <br />
                  Scoped programs:{" "}
                  {JSON.stringify(store.scopedProgramsNids, null, 2)}
                  <br />
                  Scoped regulations:{" "}
                  {JSON.stringify(store.regulations, null, 2)}
                  <br />
                  Slices:{" "}
                  {JSON.stringify(
                    store.slices.map((s) => s.map((p) => p?.id_pp_program)),
                    null,
                    2,
                  )}
                  <br />
                  <br />
                  <hr />
                  <br />
                  Displayed:
                  {JSON.stringify(
                    store.displayedPrograms?.map((p) => p?.id_pp_program),
                    null,
                    2,
                  )}
                </pre>
              </Show>

              <div class="sort" data-test="sort">
                Trier par :
                <button
                  type="button"
                  classList={{ active: store.sort === "cp" }}
                  onClick={() => {
                    setSearchParams({
                      page: undefined,
                      sort: undefined,
                    });
                    setStore("sort", "cp");
                  }}
                  data-test="filter-postal-code"
                >
                  Code postal
                </button>
                <button
                  type="button"
                  classList={{ active: store.sort === "ville" }}
                  onClick={() => {
                    setSearchParams({
                      page: undefined,
                      sort: "ville",
                    });
                    setStore("sort", "ville");
                  }}
                  data-test="filter-city"
                >
                  Ville
                </button>
              </div>
            </div>

            <div class="flex-column">
              <Show when={!store.displayedPrograms?.length}>
                <section class="no-result">
                  <p>
                    Nous n'avons pas trouvé de logement correspondant à votre
                    recherche. Nous vous conseillons d'élargir la zone
                    recherchée.
                  </p>
                </section>
              </Show>
              <section
                id="programs"
                class="programs-list"
                data-ga-zone="list"
                data-test="programs"
              >
                <ProgramsList
                  programs={store.displayedPrograms}
                  showPromoCard={showPromoCard()}
                />
              </section>

              <Show when={store.slices.length > 1}>
                <Pagination
                  currentPage={store.currentPage}
                  totalPages={store.slices.length}
                  url={path()!}
                  type="landings"
                  scrollTo="#programs"
                />
              </Show>
            </div>
          </Show>
        </div>
      </section>
    </>
  );
}
